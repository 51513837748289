import _flickity from "./flickity";
import * as _fizzyUiUtils2 from "fizzy-ui-utils";

var _fizzyUiUtils = "default" in _fizzyUiUtils2 ? _fizzyUiUtils2.default : _fizzyUiUtils2;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

// lazyload
(function (window, factory) {
  // universal module definition
  if (exports) {
    // CommonJS
    exports = factory(window, _flickity, _fizzyUiUtils);
  } else {
    // browser global
    factory(window, window.Flickity, window.fizzyUIUtils);
  }
})(window, function factory(window, Flickity, utils) {
  'use strict';

  Flickity.createMethods.push("_createLazyload");
  var proto = Flickity.prototype;

  proto._createLazyload = function () {
    this.on("select", (this || _global).lazyLoad);
  };

  proto.lazyLoad = function () {
    var lazyLoad = (this || _global).options.lazyLoad;

    if (!lazyLoad) {
      return;
    } // get adjacent cells, use lazyLoad option for adjacent count


    var adjCount = typeof lazyLoad == "number" ? lazyLoad : 0;
    var cellElems = this.getAdjacentCellElements(adjCount); // get lazy images in those cells

    var lazyImages = [];
    cellElems.forEach(function (cellElem) {
      var lazyCellImages = getCellLazyImages(cellElem);
      lazyImages = lazyImages.concat(lazyCellImages);
    }); // load lazy images

    lazyImages.forEach(function (img) {
      new LazyLoader(img, this || _global);
    }, this || _global);
  };

  function getCellLazyImages(cellElem) {
    // check if cell element is lazy image
    if (cellElem.nodeName == "IMG") {
      var lazyloadAttr = cellElem.getAttribute("data-flickity-lazyload");
      var srcAttr = cellElem.getAttribute("data-flickity-lazyload-src");
      var srcsetAttr = cellElem.getAttribute("data-flickity-lazyload-srcset");

      if (lazyloadAttr || srcAttr || srcsetAttr) {
        return [cellElem];
      }
    } // select lazy images in cell


    var lazySelector = "img[data-flickity-lazyload], " + "img[data-flickity-lazyload-src], img[data-flickity-lazyload-srcset]";
    var imgs = cellElem.querySelectorAll(lazySelector);
    return utils.makeArray(imgs);
  } // -------------------------- LazyLoader -------------------------- //

  /**
   * class to handle loading images
   * @param {Image} img - Image element
   * @param {Flickity} flickity - Flickity instance
   */


  function LazyLoader(img, flickity) {
    (this || _global).img = img;
    (this || _global).flickity = flickity;
    this.load();
  }

  LazyLoader.prototype.handleEvent = utils.handleEvent;

  LazyLoader.prototype.load = function () {
    (this || _global).img.addEventListener("load", this || _global);

    (this || _global).img.addEventListener("error", this || _global); // get src & srcset


    var src = (this || _global).img.getAttribute("data-flickity-lazyload") || (this || _global).img.getAttribute("data-flickity-lazyload-src");

    var srcset = (this || _global).img.getAttribute("data-flickity-lazyload-srcset"); // set src & serset


    (this || _global).img.src = src;

    if (srcset) {
      (this || _global).img.setAttribute("srcset", srcset);
    } // remove attr


    (this || _global).img.removeAttribute("data-flickity-lazyload");

    (this || _global).img.removeAttribute("data-flickity-lazyload-src");

    (this || _global).img.removeAttribute("data-flickity-lazyload-srcset");
  };

  LazyLoader.prototype.onload = function (event) {
    this.complete(event, "flickity-lazyloaded");
  };

  LazyLoader.prototype.onerror = function (event) {
    this.complete(event, "flickity-lazyerror");
  };

  LazyLoader.prototype.complete = function (event, className) {
    // unbind events
    (this || _global).img.removeEventListener("load", this || _global);

    (this || _global).img.removeEventListener("error", this || _global);

    var cell = (this || _global).flickity.getParentCell((this || _global).img);

    var cellElem = cell && cell.element;

    (this || _global).flickity.cellSizeChange(cellElem);

    (this || _global).img.classList.add(className);

    (this || _global).flickity.dispatchEvent("lazyLoad", event, cellElem);
  }; // -----  ----- //


  Flickity.LazyLoader = LazyLoader;
  return Flickity;
});

export default exports;