import _flickity from "./flickity";
import * as _unipointer2 from "unipointer";

var _unipointer = "default" in _unipointer2 ? _unipointer2.default : _unipointer2;

import * as _fizzyUiUtils2 from "fizzy-ui-utils";

var _fizzyUiUtils = "default" in _fizzyUiUtils2 ? _fizzyUiUtils2.default : _fizzyUiUtils2;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

// prev/next buttons
(function (window, factory) {
  // universal module definition
  if (exports) {
    // CommonJS
    exports = factory(window, _flickity, _unipointer, _fizzyUiUtils);
  } else {
    // browser global
    factory(window, window.Flickity, window.Unipointer, window.fizzyUIUtils);
  }
})(window, function factory(window, Flickity, Unipointer, utils) {
  'use strict';

  var svgURI = "http://www.w3.org/2000/svg"; // -------------------------- PrevNextButton -------------------------- //

  function PrevNextButton(direction, parent) {
    (this || _global).direction = direction;
    (this || _global).parent = parent;

    this._create();
  }

  PrevNextButton.prototype = Object.create(Unipointer.prototype);

  PrevNextButton.prototype._create = function () {
    // properties
    (this || _global).isEnabled = true;
    (this || _global).isPrevious = (this || _global).direction == -1;
    var leftDirection = (this || _global).parent.options.rightToLeft ? 1 : -1;
    (this || _global).isLeft = (this || _global).direction == leftDirection;
    var element = (this || _global).element = document.createElement("button");
    element.className = "flickity-button flickity-prev-next-button";
    element.className += (this || _global).isPrevious ? " previous" : " next"; // prevent button from submitting form http://stackoverflow.com/a/10836076/182183

    element.setAttribute("type", "button"); // init as disabled

    this.disable();
    element.setAttribute("aria-label", (this || _global).isPrevious ? "Previous" : "Next"); // create arrow

    var svg = this.createSVG();
    element.appendChild(svg); // events

    (this || _global).parent.on("select", (this || _global).update.bind(this || _global));

    this.on("pointerDown", (this || _global).parent.childUIPointerDown.bind((this || _global).parent));
  };

  PrevNextButton.prototype.activate = function () {
    this.bindStartEvent((this || _global).element);

    (this || _global).element.addEventListener("click", this || _global); // add to DOM


    (this || _global).parent.element.appendChild((this || _global).element);
  };

  PrevNextButton.prototype.deactivate = function () {
    // remove from DOM
    (this || _global).parent.element.removeChild((this || _global).element); // click events


    this.unbindStartEvent((this || _global).element);

    (this || _global).element.removeEventListener("click", this || _global);
  };

  PrevNextButton.prototype.createSVG = function () {
    var svg = document.createElementNS(svgURI, "svg");
    svg.setAttribute("class", "flickity-button-icon");
    svg.setAttribute("viewBox", "0 0 100 100");
    var path = document.createElementNS(svgURI, "path");
    var pathMovements = getArrowMovements((this || _global).parent.options.arrowShape);
    path.setAttribute("d", pathMovements);
    path.setAttribute("class", "arrow"); // rotate arrow

    if (!(this || _global).isLeft) {
      path.setAttribute("transform", "translate(100, 100) rotate(180) ");
    }

    svg.appendChild(path);
    return svg;
  }; // get SVG path movmement


  function getArrowMovements(shape) {
    // use shape as movement if string
    if (typeof shape == "string") {
      return shape;
    } // create movement string


    return "M " + shape.x0 + ",50" + " L " + shape.x1 + "," + (shape.y1 + 50) + " L " + shape.x2 + "," + (shape.y2 + 50) + " L " + shape.x3 + ",50 " + " L " + shape.x2 + "," + (50 - shape.y2) + " L " + shape.x1 + "," + (50 - shape.y1) + " Z";
  }

  PrevNextButton.prototype.handleEvent = utils.handleEvent;

  PrevNextButton.prototype.onclick = function () {
    if (!(this || _global).isEnabled) {
      return;
    }

    (this || _global).parent.uiChange();

    var method = (this || _global).isPrevious ? "previous" : "next";

    (this || _global).parent[method]();
  }; // -----  ----- //


  PrevNextButton.prototype.enable = function () {
    if ((this || _global).isEnabled) {
      return;
    }

    (this || _global).element.disabled = false;
    (this || _global).isEnabled = true;
  };

  PrevNextButton.prototype.disable = function () {
    if (!(this || _global).isEnabled) {
      return;
    }

    (this || _global).element.disabled = true;
    (this || _global).isEnabled = false;
  };

  PrevNextButton.prototype.update = function () {
    // index of first or last slide, if previous or next
    var slides = (this || _global).parent.slides; // enable is wrapAround and at least 2 slides

    if ((this || _global).parent.options.wrapAround && slides.length > 1) {
      this.enable();
      return;
    }

    var lastIndex = slides.length ? slides.length - 1 : 0;
    var boundIndex = (this || _global).isPrevious ? 0 : lastIndex;
    var method = (this || _global).parent.selectedIndex == boundIndex ? "disable" : "enable";
    this[method]();
  };

  PrevNextButton.prototype.destroy = function () {
    this.deactivate();
    this.allOff();
  }; // -------------------------- Flickity prototype -------------------------- //


  utils.extend(Flickity.defaults, {
    prevNextButtons: true,
    arrowShape: {
      x0: 10,
      x1: 60,
      y1: 50,
      x2: 70,
      y2: 40,
      x3: 30
    }
  });
  Flickity.createMethods.push("_createPrevNextButtons");
  var proto = Flickity.prototype;

  proto._createPrevNextButtons = function () {
    if (!(this || _global).options.prevNextButtons) {
      return;
    }

    (this || _global).prevButton = new PrevNextButton(-1, this || _global);
    (this || _global).nextButton = new PrevNextButton(1, this || _global);
    this.on("activate", (this || _global).activatePrevNextButtons);
  };

  proto.activatePrevNextButtons = function () {
    (this || _global).prevButton.activate();

    (this || _global).nextButton.activate();

    this.on("deactivate", (this || _global).deactivatePrevNextButtons);
  };

  proto.deactivatePrevNextButtons = function () {
    (this || _global).prevButton.deactivate();

    (this || _global).nextButton.deactivate();

    this.off("deactivate", (this || _global).deactivatePrevNextButtons);
  }; // --------------------------  -------------------------- //


  Flickity.PrevNextButton = PrevNextButton;
  return Flickity;
});

export default exports;