var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

// slide
(function (window, factory) {
  // universal module definition
  if (exports) {
    // CommonJS
    exports = factory();
  } else {
    // browser global
    window.Flickity = window.Flickity || {};
    window.Flickity.Slide = factory();
  }
})(window, function factory() {
  'use strict';

  function Slide(parent) {
    (this || _global).parent = parent;
    (this || _global).isOriginLeft = parent.originSide == "left";
    (this || _global).cells = [];
    (this || _global).outerWidth = 0;
    (this || _global).height = 0;
  }

  var proto = Slide.prototype;

  proto.addCell = function (cell) {
    (this || _global).cells.push(cell);

    (this || _global).outerWidth += cell.size.outerWidth;
    (this || _global).height = Math.max(cell.size.outerHeight, (this || _global).height); // first cell stuff

    if ((this || _global).cells.length == 1) {
      (this || _global).x = cell.x; // x comes from first cell

      var beginMargin = (this || _global).isOriginLeft ? "marginLeft" : "marginRight";
      (this || _global).firstMargin = cell.size[beginMargin];
    }
  };

  proto.updateTarget = function () {
    var endMargin = (this || _global).isOriginLeft ? "marginRight" : "marginLeft";
    var lastCell = this.getLastCell();
    var lastMargin = lastCell ? lastCell.size[endMargin] : 0;
    var slideWidth = (this || _global).outerWidth - ((this || _global).firstMargin + lastMargin);
    (this || _global).target = (this || _global).x + (this || _global).firstMargin + slideWidth * (this || _global).parent.cellAlign;
  };

  proto.getLastCell = function () {
    return (this || _global).cells[(this || _global).cells.length - 1];
  };

  proto.select = function () {
    (this || _global).cells.forEach(function (cell) {
      cell.select();
    });
  };

  proto.unselect = function () {
    (this || _global).cells.forEach(function (cell) {
      cell.unselect();
    });
  };

  proto.getCellElements = function () {
    return (this || _global).cells.map(function (cell) {
      return cell.element;
    });
  };

  return Slide;
});

export default exports;