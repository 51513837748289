import _flickity from "./flickity";
import _drag from "./drag";
import _prevNextButton from "./prev-next-button";
import _pageDots from "./page-dots";
import _player from "./player";
import _addRemoveCell from "./add-remove-cell";
import _lazyload from "./lazyload";
var exports = {};

/*!
 * Flickity v2.3.0
 * Touch, responsive, flickable carousels
 *
 * Licensed GPLv3 for open source use
 * or Flickity Commercial License for commercial use
 *
 * https://flickity.metafizzy.co
 * Copyright 2015-2021 Metafizzy
 */
(function (window, factory) {
  // universal module definition
  if (exports) {
    // CommonJS
    exports = factory(_flickity, _drag, _prevNextButton, _pageDots, _player, _addRemoveCell, _lazyload);
  }
})(window, function factory(Flickity) {
  return Flickity;
});

export default exports;