import * as _fizzyUiUtils2 from "fizzy-ui-utils";

var _fizzyUiUtils = "default" in _fizzyUiUtils2 ? _fizzyUiUtils2.default : _fizzyUiUtils2;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

// animate
(function (window, factory) {
  // universal module definition
  if (exports) {
    // CommonJS
    exports = factory(window, _fizzyUiUtils);
  } else {
    // browser global
    window.Flickity = window.Flickity || {};
    window.Flickity.animatePrototype = factory(window, window.fizzyUIUtils);
  }
})(window, function factory(window, utils) {
  'use strict'; // -------------------------- animate -------------------------- //

  var proto = {};

  proto.startAnimation = function () {
    if ((this || _global).isAnimating) {
      return;
    }

    (this || _global).isAnimating = true;
    (this || _global).restingFrames = 0;
    this.animate();
  };

  proto.animate = function () {
    this.applyDragForce();
    this.applySelectedAttraction();
    var previousX = (this || _global).x;
    this.integratePhysics();
    this.positionSlider();
    this.settle(previousX); // animate next frame

    if ((this || _global).isAnimating) {
      var _this = this || _global;

      requestAnimationFrame(function animateFrame() {
        _this.animate();
      });
    }
  };

  proto.positionSlider = function () {
    var x = (this || _global).x; // wrap position around

    if ((this || _global).options.wrapAround && (this || _global).cells.length > 1) {
      x = utils.modulo(x, (this || _global).slideableWidth);
      x -= (this || _global).slideableWidth;
      this.shiftWrapCells(x);
    }

    this.setTranslateX(x, (this || _global).isAnimating);
    this.dispatchScrollEvent();
  };

  proto.setTranslateX = function (x, is3d) {
    x += (this || _global).cursorPosition; // reverse if right-to-left and using transform

    x = (this || _global).options.rightToLeft ? -x : x;
    var translateX = this.getPositionValue(x); // use 3D transforms for hardware acceleration on iOS
    // but use 2D when settled, for better font-rendering

    (this || _global).slider.style.transform = is3d ? "translate3d(" + translateX + ",0,0)" : "translateX(" + translateX + ")";
  };

  proto.dispatchScrollEvent = function () {
    var firstSlide = (this || _global).slides[0];

    if (!firstSlide) {
      return;
    }

    var positionX = -(this || _global).x - firstSlide.target;
    var progress = positionX / (this || _global).slidesWidth;
    this.dispatchEvent("scroll", null, [progress, positionX]);
  };

  proto.positionSliderAtSelected = function () {
    if (!(this || _global).cells.length) {
      return;
    }

    (this || _global).x = -(this || _global).selectedSlide.target;
    (this || _global).velocity = 0; // stop wobble

    this.positionSlider();
  };

  proto.getPositionValue = function (position) {
    if ((this || _global).options.percentPosition) {
      // percent position, round to 2 digits, like 12.34%
      return Math.round(position / (this || _global).size.innerWidth * 10000) * 0.01 + "%";
    } else {
      // pixel positioning
      return Math.round(position) + "px";
    }
  };

  proto.settle = function (previousX) {
    // keep track of frames where x hasn't moved
    var isResting = !(this || _global).isPointerDown && Math.round((this || _global).x * 100) == Math.round(previousX * 100);

    if (isResting) {
      (this || _global).restingFrames++;
    } // stop animating if resting for 3 or more frames


    if ((this || _global).restingFrames > 2) {
      (this || _global).isAnimating = false;
      delete (this || _global).isFreeScrolling; // render position with translateX when settled

      this.positionSlider();
      this.dispatchEvent("settle", null, [(this || _global).selectedIndex]);
    }
  };

  proto.shiftWrapCells = function (x) {
    // shift before cells
    var beforeGap = (this || _global).cursorPosition + x;

    this._shiftCells((this || _global).beforeShiftCells, beforeGap, -1); // shift after cells


    var afterGap = (this || _global).size.innerWidth - (x + (this || _global).slideableWidth + (this || _global).cursorPosition);

    this._shiftCells((this || _global).afterShiftCells, afterGap, 1);
  };

  proto._shiftCells = function (cells, gap, shift) {
    for (var i = 0; i < cells.length; i++) {
      var cell = cells[i];
      var cellShift = gap > 0 ? shift : 0;
      cell.wrapShift(cellShift);
      gap -= cell.size.outerWidth;
    }
  };

  proto._unshiftCells = function (cells) {
    if (!cells || !cells.length) {
      return;
    }

    for (var i = 0; i < cells.length; i++) {
      cells[i].wrapShift(0);
    }
  }; // -------------------------- physics -------------------------- //


  proto.integratePhysics = function () {
    (this || _global).x += (this || _global).velocity;
    (this || _global).velocity *= this.getFrictionFactor();
  };

  proto.applyForce = function (force) {
    (this || _global).velocity += force;
  };

  proto.getFrictionFactor = function () {
    return 1 - (this || _global).options[(this || _global).isFreeScrolling ? "freeScrollFriction" : "friction"];
  };

  proto.getRestingPosition = function () {
    // my thanks to Steven Wittens, who simplified this math greatly
    return (this || _global).x + (this || _global).velocity / (1 - this.getFrictionFactor());
  };

  proto.applyDragForce = function () {
    if (!(this || _global).isDraggable || !(this || _global).isPointerDown) {
      return;
    } // change the position to drag position by applying force


    var dragVelocity = (this || _global).dragX - (this || _global).x;
    var dragForce = dragVelocity - (this || _global).velocity;
    this.applyForce(dragForce);
  };

  proto.applySelectedAttraction = function () {
    // do not attract if pointer down or no slides
    var dragDown = (this || _global).isDraggable && (this || _global).isPointerDown;

    if (dragDown || (this || _global).isFreeScrolling || !(this || _global).slides.length) {
      return;
    }

    var distance = (this || _global).selectedSlide.target * -1 - (this || _global).x;
    var force = distance * (this || _global).options.selectedAttraction;
    this.applyForce(force);
  };

  return proto;
});

export default exports;