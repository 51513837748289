import * as _evEmitter2 from "ev-emitter";

var _evEmitter = "default" in _evEmitter2 ? _evEmitter2.default : _evEmitter2;

import * as _fizzyUiUtils2 from "fizzy-ui-utils";

var _fizzyUiUtils = "default" in _fizzyUiUtils2 ? _fizzyUiUtils2.default : _fizzyUiUtils2;

import _flickity from "./flickity";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

// player & autoPlay
(function (window, factory) {
  // universal module definition
  if (exports) {
    // CommonJS
    exports = factory(_evEmitter, _fizzyUiUtils, _flickity);
  } else {
    // browser global
    factory(window.EvEmitter, window.fizzyUIUtils, window.Flickity);
  }
})(window, function factory(EvEmitter, utils, Flickity) {
  'use strict'; // -------------------------- Player -------------------------- //

  function Player(parent) {
    (this || _global).parent = parent;
    (this || _global).state = "stopped"; // visibility change event handler

    (this || _global).onVisibilityChange = (this || _global).visibilityChange.bind(this || _global);
    (this || _global).onVisibilityPlay = (this || _global).visibilityPlay.bind(this || _global);
  }

  Player.prototype = Object.create(EvEmitter.prototype); // start play

  Player.prototype.play = function () {
    if ((this || _global).state == "playing") {
      return;
    } // do not play if page is hidden, start playing when page is visible


    var isPageHidden = document.hidden;

    if (isPageHidden) {
      document.addEventListener("visibilitychange", (this || _global).onVisibilityPlay);
      return;
    }

    (this || _global).state = "playing"; // listen to visibility change

    document.addEventListener("visibilitychange", (this || _global).onVisibilityChange); // start ticking

    this.tick();
  };

  Player.prototype.tick = function () {
    // do not tick if not playing
    if ((this || _global).state != "playing") {
      return;
    }

    var time = (this || _global).parent.options.autoPlay; // default to 3 seconds

    time = typeof time == "number" ? time : 3000;

    var _this = this || _global; // HACK: reset ticks if stopped and started within interval


    this.clear();
    (this || _global).timeout = setTimeout(function () {
      _this.parent.next(true);

      _this.tick();
    }, time);
  };

  Player.prototype.stop = function () {
    (this || _global).state = "stopped";
    this.clear(); // remove visibility change event

    document.removeEventListener("visibilitychange", (this || _global).onVisibilityChange);
  };

  Player.prototype.clear = function () {
    clearTimeout((this || _global).timeout);
  };

  Player.prototype.pause = function () {
    if ((this || _global).state == "playing") {
      (this || _global).state = "paused";
      this.clear();
    }
  };

  Player.prototype.unpause = function () {
    // re-start play if paused
    if ((this || _global).state == "paused") {
      this.play();
    }
  }; // pause if page visibility is hidden, unpause if visible


  Player.prototype.visibilityChange = function () {
    var isPageHidden = document.hidden;
    this[isPageHidden ? "pause" : "unpause"]();
  };

  Player.prototype.visibilityPlay = function () {
    this.play();
    document.removeEventListener("visibilitychange", (this || _global).onVisibilityPlay);
  }; // -------------------------- Flickity -------------------------- //


  utils.extend(Flickity.defaults, {
    pauseAutoPlayOnHover: true
  });
  Flickity.createMethods.push("_createPlayer");
  var proto = Flickity.prototype;

  proto._createPlayer = function () {
    (this || _global).player = new Player(this || _global);
    this.on("activate", (this || _global).activatePlayer);
    this.on("uiChange", (this || _global).stopPlayer);
    this.on("pointerDown", (this || _global).stopPlayer);
    this.on("deactivate", (this || _global).deactivatePlayer);
  };

  proto.activatePlayer = function () {
    if (!(this || _global).options.autoPlay) {
      return;
    }

    (this || _global).player.play();

    (this || _global).element.addEventListener("mouseenter", this || _global);
  }; // Player API, don't hate the ... thanks I know where the door is


  proto.playPlayer = function () {
    (this || _global).player.play();
  };

  proto.stopPlayer = function () {
    (this || _global).player.stop();
  };

  proto.pausePlayer = function () {
    (this || _global).player.pause();
  };

  proto.unpausePlayer = function () {
    (this || _global).player.unpause();
  };

  proto.deactivatePlayer = function () {
    (this || _global).player.stop();

    (this || _global).element.removeEventListener("mouseenter", this || _global);
  }; // ----- mouseenter/leave ----- //
  // pause auto-play on hover


  proto.onmouseenter = function () {
    if (!(this || _global).options.pauseAutoPlayOnHover) {
      return;
    }

    (this || _global).player.pause();

    (this || _global).element.addEventListener("mouseleave", this || _global);
  }; // resume auto-play on hover off


  proto.onmouseleave = function () {
    (this || _global).player.unpause();

    (this || _global).element.removeEventListener("mouseleave", this || _global);
  }; // -----  ----- //


  Flickity.Player = Player;
  return Flickity;
});

export default exports;